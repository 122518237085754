// eslint-disable no-undefs
// @ts-nocheck
// @ts-ignore
import {
    DialogContent,
    HStack,
    Input,
    SelectContent,
    SelectItem,
    SelectRoot,
    SelectTrigger,
    SelectValueText,
    Stack,
    Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {DialogActionTrigger, DialogBody, DialogFooter, DialogHeader, DialogRoot, DialogTitle} from "./ui/dialog";
import {Field} from "./ui/field";
import {Button} from "./ui/button";
import {updateUser} from "../api/user";
import {getUserRole} from "../utils/tokenUtils";

const UpdateUserModel = ({isUpdateModelOpened, setIsUpdateModelOpened, fetchData, user}) => {
    const [password, setPassword] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [patronymicName, setPatronymicName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [jobTitle, setJobTitle] = useState<string>("");
    const [role, setRole] = useState<string>("")
    const [inn, setInn] = useState<string>("");
    const [brandCode, setBrandCode] = useState<string>("");
    const [organizationInn, setOrganizationInn] = useState<string>("");
    const [isAddButtonDisable, setIsAddButtonDisable] = useState(true);
    const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);
    const [isAlreadyExistsError, setIsAlreadyExistsError] = useState(false);
    const [isInnError, setIsInnError] = useState(false);
    const [isError, setError] = useState(false);

    useEffect(() => {
        setIsAddButtonDisable(!(
            (password === "" || /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/g.test(password))
            && (organizationInn === "" || /^(\d{10}|\d{12})$/.test(organizationInn))
        ));
    }, [password, organizationInn]);

    const updateUserHandler = async () => {
        setIsAddButtonLoading(true)
        setIsAlreadyExistsError(false)
        setIsInnError(false)
        setError(false)

        try {
            await updateUser(user.id, {
                login: "",
                password: password,
                firstName: firstName == "" ? user.firstName : firstName,
                lastName: lastName == "" ? user.lastName : lastName,
                patronymicName: patronymicName == "" ? user.patronymicName : patronymicName,
                userRole: role == "" || role == undefined ? user.userRole : role,
                jobTitle: jobTitle == "" ? user.jobTitle : jobTitle,
                organizationInn: organizationInn == "" ? user.organizationInn : organizationInn,
                inn: inn == "" ? user.inn : inn,
                brandCode: brandCode == "" ? user.brandCode : brandCode
            })
            setIsUpdateModelOpened(false)
            await fetchData()
        } catch (e) {
            // @ts-ignore
            if (e.response.data.message.includes("User already exists")) {
                setIsAlreadyExistsError(true)
            } else if (e.response.data.message.includes("Organization not found")) {
                setIsInnError(true)
            } else {
                setError(true)
            }

        }

        setIsAddButtonLoading(false)
    }

    useEffect(() => {
        setIsAddButtonLoading(false)
        setIsAlreadyExistsError(false)
        setIsInnError(false)
        setError(false)

        setPassword(user.password ? user.password : "")
        setFirstName(user.firstName)
        setPatronymicName(user.patronymicName)
        setLastName(user.lastName)
        setJobTitle(user.jobTitle)
        setRole(user.role)
        setInn(user.inn)
        setBrandCode(user.brandCode)
        setOrganizationInn(user.organizationInn ? user.organizationInn : "")
    }, [isUpdateModelOpened]);

    return (
        <HStack position="absolute" width="100%" style={{ marginTop: '-60px'}}>
            <DialogRoot open={isUpdateModelOpened}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Обновление пользователя</DialogTitle>
                    </DialogHeader>
                    <DialogBody>
                        <form>
                            <Stack width="full">
                                <Field label="Пароль пользователя" color="#88898A">
                                    <Input value={password} onChange={e => setPassword(e.target.value)}
                                           placeholder="Пароль"
                                           color="#2F4F4F"/>
                                </Field>
                                <Field label="Фамилия пользователя" color="#88898A">
                                    <Input value={lastName} onChange={e => setLastName(e.target.value)}
                                           placeholder="Фамилия" color="#2F4F4F"/>
                                </Field>
                                <Field label="Имя пользователя" color="#88898A">
                                    <Input value={firstName} onChange={e => setFirstName(e.target.value)}
                                           placeholder="Имя"
                                           color="#2F4F4F"/>
                                </Field>
                                <Field label="Отчество пользователя" color="#88898A">
                                    <Input value={patronymicName} onChange={e => setPatronymicName(e.target.value)}
                                           placeholder="Отчество" color="#2F4F4F"/>
                                </Field>
                                <Field label="Должность пользователя" color="#88898A">
                                    <Input value={jobTitle} onChange={e => setJobTitle(e.target.value)}
                                           placeholder="Должность" color="#2F4F4F"/>
                                </Field>
                                <Field label="Роль пользователя" color="#88898A">
                                    <SelectRoot cursor="pointer" collection={getUserRole()}
                                                onValueChange={({value}) => setRole(value[0])} defaultValue={[user.userRole]}>
                                        <SelectTrigger cursor="pointer">
                                            <SelectValueText color="#2F4F4F" placeholder="Выбрать роль пользователя"/>
                                        </SelectTrigger>
                                        <SelectContent cursor="pointer" position="absolute" width="100%"
                                                       color="#2F4F4F">
                                            {getUserRole().items.map((movie) => (
                                                <SelectItem cursor="pointer" item={movie} key={movie.value}>
                                                    {movie.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </SelectRoot>
                                </Field>
                                <Field label="ИНН огранизации пользователя (10  или 12 цифр)" color="#88898A">
                                    <Input value={organizationInn} onChange={e => setOrganizationInn(e.target.value)}
                                           placeholder="ИНН огранизации" color="#2F4F4F"/>
                                </Field>
                                <Field label="ИНН пользователя" color="#88898A">
                                    <Input value={inn} onChange={e => setInn(e.target.value)} placeholder="ИНН"
                                           color="#2F4F4F"/>
                                </Field>
                                <Field label="Шифр клейма пользователя" color="#88898A">
                                    <Input value={brandCode} onChange={e => setBrandCode(e.target.value)}
                                           placeholder="Шифр клейма" color="#2F4F4F"/>
                                </Field>
                                {isAlreadyExistsError &&
                                    <Text textStyle="sm" color="red">Пользователь с таким логином уже есть в
                                        базе</Text>}
                                {isInnError &&
                                    <Text textStyle="sm" color="red">Организации с таким ИНН нет в базе</Text>}
                                {isError &&
                                    <Text textStyle="sm" color="red">Что-то пошло не так, попробуйте еще раз</Text>}
                            </Stack>
                        </form>
                    </DialogBody>
                    <DialogFooter>
                        <DialogActionTrigger asChild>
                            <Button variant="outline" onClick={() => setIsUpdateModelOpened(false)}
                                    disabled={isAddButtonLoading}>Отмена</Button>
                        </DialogActionTrigger>
                        <Button disabled={isAddButtonDisable} loading={isAddButtonLoading}
                                onClick={updateUserHandler}>Обновить</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot> </HStack>
    )
}

export default UpdateUserModel