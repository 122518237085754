import {Flex, Text} from "@chakra-ui/react";
import React from "react";

const EmptyContent = () => {
    return (
        <Flex width="100%" justify="space-between" paddingX="6">
            <Text color="red">Пользователь с ролью поверитель не имеет доступа в админ панель.</Text>
        </Flex>
    )
}

export default EmptyContent