import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from 'axios';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.baseURL = 'http://185.41.161.81:8081/';
// axios.defaults.baseURL = 'http://localhost:8081/';

root.render(
    <App/>
);