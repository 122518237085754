import axios from 'axios';
import {getJwt} from "../utils/tokenUtils";

export const getOrganizations = (page) => {
    return axios.get(`/api/admin/organizations?pageNumber=${page}`, { headers: {Authorization: 'Bearer ' + getJwt()}});
}

export const addOrganization = (name, inn) => {
    return axios.post('/api/admin/organizations', {name, inn}, { headers: {Authorization: 'Bearer ' + getJwt()}});
}

export const updateOrganization = (id, name) => {
    return axios.post(`/api/admin/organizations/${id}`, {name, inn: ""}, { headers: {Authorization: 'Bearer ' + getJwt()}});
}

export const deleteOrganization = (id) => {
    return axios.delete(`/api/admin/organizations/${id}`,{ headers: {Authorization: 'Bearer ' + getJwt()}});
}