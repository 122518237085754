import {Spinner, Text, VStack} from "@chakra-ui/react";
import React from "react";

const Loader = () => {
    return (
        <VStack colorPalette="teal" paddingY="6">
            <Spinner color="colorPalette.600" size="lg" borderWidth="4px"/>
            <Text color="colorPalette.600">Загрузка...</Text>
        </VStack>
    )
}

export default Loader