// @ts-nocheck
/* eslint-disable */
import React from 'react';
import {Navigate} from 'react-router-dom';

import {getJwt} from '../utils/tokenUtils';

const PrivateRoute = ({ children }) => {
    return getJwt() ? children : <Navigate to="/login" />;
};

export default PrivateRoute;