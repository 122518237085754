import React, {useEffect, useState} from "react";
import {DialogContent, Flex, HStack, Table} from "@chakra-ui/react"
import Loader from "./Loader";
import {deleteOrganization, getOrganizations} from "../api/organization";
import {FaArrowsRotate, FaTrashCan} from "react-icons/fa6";
import {Button} from "./ui/button";
import {PaginationNextTrigger, PaginationPageText, PaginationPrevTrigger, PaginationRoot} from "./ui/pagination";
import {DialogActionTrigger, DialogBody, DialogFooter, DialogHeader, DialogRoot, DialogTitle} from "./ui/dialog";
import AddOrganizationModel from "./AddOrganizationModel";
import {PageType} from "../type/CommonType";
import UpdateOrganizationModel from "./UpdateOrganizationModel";

const Organization = () => {
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganization] = useState({
        content: [{id: 1, name: "", inn: ""}],
        page: {totalElements: 1}
    });
    const [updateOrganization, setUpdateOrganization] = useState({});
    const [isDeleteModelOpened, setIsDeleteModelOpened] = useState(false);
    const [isUpdateModelOpened, setIsUpdateModelOpened] = useState(false);
    const [isCreateModelOpened, setIsCreateModelOpened] = useState(false);
    const [deleteOrganizationId, setDeleteOrganizationId] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number | null>(0);

    const fetchData = async () => {
        setLoading(true)
        const {data} = await getOrganizations(currentPage)
        setOrganization(data)
        setLoading(false)
    }

    // @ts-ignore
    useEffect(() => {
        fetchData().then()
    }, []);

    useEffect(() => {
        fetchData().then()
    }, [currentPage]);

    const handleDeleteOpenModel = (organizationId: number) => {
        setDeleteOrganizationId(organizationId)
        setIsDeleteModelOpened(true)
    }

    const handleDeleteOrganization = async () => {
        await deleteOrganization(deleteOrganizationId)
        setDeleteOrganizationId(null)
        setIsDeleteModelOpened(false)

        await fetchData().then()
    }

    const handleUpdateOpenModel = (organizationId: number) => {
        setUpdateOrganization(organizations && organizations.content.find(org => org.id === organizationId)!!)
        setIsUpdateModelOpened(true)
    }

    const updatePage = (page: number) => {
        setCurrentPage(page - 1)
    }

    // @ts-ignore
    return (
        <>
            <Flex direction="column" gap={4}>
                <AddOrganizationModel
                    isCreateModelOpened={isCreateModelOpened}
                    setIsCreateModelOpened={setIsCreateModelOpened}
                    fetchData={fetchData}
                />
                <UpdateOrganizationModel
                    isUpdateModelOpened={isUpdateModelOpened}
                    setIsUpdateModelOpened={setIsUpdateModelOpened}
                    fetchData={fetchData}
                    organization={updateOrganization}
                />
                <Flex justify="end" gap={5} align="center">
                    <Button disabled={loading} onClick={() => setIsCreateModelOpened(true)}>Добавить компанию</Button>
                    <PaginationRoot
                        disabled={loading}
                        count={organizations.page.totalElements}
                        pageSize={35}
                        defaultPage={1}
                        onPageChange={(e: PageType) => updatePage(e.page)}
                    >
                        <HStack gap="4">
                            <PaginationPageText flex="1"/>
                            <PaginationPrevTrigger/>
                            <PaginationNextTrigger/>
                        </HStack>
                    </PaginationRoot>
                </Flex>
                {loading ?
                    <Loader/> :
                    <>
                        <Table.Root size="sm" interactive>
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColumnHeader width='30px'>Идентификатор</Table.ColumnHeader>
                                    <Table.ColumnHeader>Наименование организации</Table.ColumnHeader>
                                    <Table.ColumnHeader>ИНН</Table.ColumnHeader>
                                    <Table.ColumnHeader></Table.ColumnHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {organizations.content && organizations.content.map((organization) => (
                                    <Table.Row key={organization.id}>
                                        <Table.Cell>{organization.id}</Table.Cell>
                                        <Table.Cell>{organization.name}</Table.Cell>
                                        <Table.Cell>{organization.inn}</Table.Cell>
                                        <Table.Cell textAlign="end">
                                            <Flex justify="end" gap={3} align="center">
                                                <Button variant="outline"
                                                        onClick={() => handleUpdateOpenModel(organization.id)}><FaArrowsRotate /></Button>
                                                <Button variant="outline"
                                                        onClick={() => handleDeleteOpenModel(organization.id)}><FaTrashCan/></Button>
                                            </Flex>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    </>}
                <HStack position="absolute" width="100%">
                    <DialogRoot open={isDeleteModelOpened} onInteractOutside={() => setIsDeleteModelOpened(false)}
                                placement="top">
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>Удаление организации</DialogTitle>
                            </DialogHeader>
                            <DialogBody>
                                <p>
                                    Вы точно хотите удалить организацию из базы?
                                </p>
                            </DialogBody>
                            <DialogFooter>
                                <DialogActionTrigger asChild>
                                    <Button variant="outline"
                                            onClick={() => setIsDeleteModelOpened(false)}>Отмена</Button>
                                </DialogActionTrigger>
                                <Button onClick={handleDeleteOrganization}>Удалить</Button>
                            </DialogFooter>
                        </DialogContent>
                    </DialogRoot>
                </HStack>
            </Flex>
        </>
    )
}

export default Organization