import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {
    defaultSystem,
    ChakraProvider
} from "@chakra-ui/react";
import Login from './pages/Login';
import AdminPanel from "./pages/AdminPanel";
import PrivateRoute from "./utils/privateRoute";
import {Navigate} from "react-router";

function App() {
    return (
        <ChakraProvider value={defaultSystem}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate replace to="/admin-panel"/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/admin-panel" element={<PrivateRoute><AdminPanel/></PrivateRoute>}/>
                </Routes>
            </BrowserRouter>
        </ChakraProvider>
    );
}

export default App;
