import React, {useEffect} from "react";
import {Flex} from "@chakra-ui/react"
import Header from "../components/Header";
import EmptyContent from "../components/EmptyContent";
import {decodeJwt, removeJwt} from "../utils/tokenUtils";
import AdminContent from "../components/AdminContent";
import {useNavigate} from "react-router-dom";


const AdminPanel = () => {
    const navigate = useNavigate()
    const isShowAdminContent = () => {
        // @ts-ignore
        return decodeJwt('role')[0] != "ROLE_VERIFIER"
    }

    useEffect(() => {
        if (decodeJwt('exp') < (Date.now() / 1000 | 0)) {
            navigate("/login")
            removeJwt()
        }
    }, []);

    return (
        <Flex gap="6" justify="space-between" paddingX="5" paddingY="2" direction="column">
            <Header/>
            {isShowAdminContent() ? <AdminContent/> : <EmptyContent/>}
        </Flex>

    )
}

export default AdminPanel;
